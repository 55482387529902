import { DateRangePicker } from 'rsuite';
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  subYears,
} from 'date-fns';
import { ReactNode } from 'react';

type Placement4 = 'top' | 'bottom' | 'right' | 'left';
type Placement8 =
  | 'bottomStart'
  | 'bottomEnd'
  | 'topStart'
  | 'topEnd'
  | 'leftStart'
  | 'rightStart'
  | 'leftEnd'
  | 'rightEnd';
type PlacementAuto =
  | 'auto'
  | 'autoVertical'
  | 'autoVerticalStart'
  | 'autoVerticalEnd'
  | 'autoHorizontal'
  | 'autoHorizontalStart'
  | 'autoHorizontalEnd';
type Placement = Placement4 | Placement8 | PlacementAuto;

interface filterProps {
  defaultDates: [Date, Date];
  handleDateChange: (value: [Date, Date]) => void;
  placement?: Placement | undefined;
}

interface Range {
  label: ReactNode;
  value: any;
  closeOverlay?: boolean;

  // Sets the position where the predefined range is displayed, the default is bottom.
  placement?: 'bottom' | 'left';
}

const CustomDatePicker = ({
  defaultDates,
  handleDateChange,
  placement,
}: filterProps) => {
  const customRanges: Range[] = [
    {
      label: 'Today',
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: 'Yesterday',
      value: [
        startOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
        endOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
      ],
    },
    {
      label: 'This Week',
      value: [
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ],
    },
    {
      label: 'Last Week',
      value: [
        startOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), {
          weekStartsOn: 1,
        }),
        endOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), {
          weekStartsOn: 1,
        }),
      ],
    },
    {
      label: 'This Month',
      value: [startOfMonth(new Date()), endOfMonth(new Date())],
    },
    {
      label: 'Last Month',
      value: [
        startOfMonth(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ),
        endOfMonth(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ),
      ],
    },
    {
      label: 'This Year',
      value: [startOfYear(new Date()), endOfYear(new Date())],
    },
    {
      label: 'Last Year',
      value: [
        startOfYear(subYears(new Date(), 1)),
        endOfYear(subYears(new Date(), 1)),
      ],
    },
  ];

  return (
    <>
      {/* date picker */}
      <DateRangePicker
        className='w-full'
        placement={placement || 'leftStart'}
        value={defaultDates}
        onChange={(e: any) => {
          handleDateChange(e);
        }}
        ranges={customRanges}
        cleanable={true}
      />
    </>
  );
};

export default CustomDatePicker;
